/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { SNACK_BAR_STATUSES, SnackBar } from "@ftbpro/mm-admin-ui-components";
import { useRecoilState, useRecoilValue } from "recoil";
import { campaignMessageState } from "./createCampaignMessage.atom";
import { useEffect } from "react";


const styles = {
  errorContainer: css({
    position: "absolute",
    top: 0,
    left: 0,
  }),
}

export const CreateCampaignMessage = () => {
  const [messageState, setMessageState] = useRecoilState(campaignMessageState);
  const { open, stayOpen = false, status = SNACK_BAR_STATUSES.ERROR, message } = messageState;

  useEffect(() => {
    if (!stayOpen) {
      setTimeout(() => {
        setMessageState({ open: false, message: '', status: SNACK_BAR_STATUSES.ERROR, stayOpen: false });
      }, 3000);
    }
  }, [stayOpen, message, status, open]);


  return (
    open ?
      <div css={styles.errorContainer}>
        <SnackBar text={message} status={status} stayOpen={stayOpen} />
      </div>
      : null
  );
}
