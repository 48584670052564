/** @jsxImportSource @emotion/react */
import { Input, Loader,TextButton } from "@ftbpro/mm-admin-ui-components"
import { CampaignsServiceProvider } from "../../../../../../../../../../../../../services/campaigns/CampaignsServiceProvider"
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useState } from "react";
import { templatesRefreshTrigger, templateState } from "../../campaignsTemplate.atom";
import { css } from "@emotion/react";
import { campaignsListState } from "../../../../../../../../createCampaign.selector";
import { campaignMessageState } from "../../../../../../../CreateCampaignMessage/createCampaignMessage.atom";


const styles = {
  buttons: css({
    display: "flex",
    justifyContent: "right",
  }),
  input: css({
    marginTop: "24px"
  }),
  loader: css({
    display: "flex",
    justifyContent: "right",
    marginTop: "10px"
  }),
  snackBar: css({
    position: 'absolute',
    top: '-55px',
    right: '0px',
    width: '100%',
  }),
}

export const CreateNewTemplate = () => {
  const [templateName, setTemplateName] = useState('');
  const [loading, setLoading] = useState(false);
  const setTemplate = useSetRecoilState(templateState);
  const setRefreshTemplatesTrigger = useSetRecoilState(templatesRefreshTrigger);
  const campaignsList = useRecoilValue(campaignsListState);
  const setError = useSetRecoilState(campaignMessageState);

  const onTemplateNameChange = (e: React.SyntheticEvent<HTMLElement, Event>) => {
    const inputElement = e.target as HTMLInputElement;
    setTemplateName(inputElement.value);
  }

  const createTemplate = async () => {
    const newTemplate = {
      name: templateName,
      platform: 'facebook',
      template: { template: campaignsList }
    }
    try {
      setLoading(true);
      const templateId = await CampaignsServiceProvider.createTemplate(newTemplate);
      setTemplateName('');
      setLoading(false);
      setTemplate((prevTemplate) => {
        return {
          ...prevTemplate,
          tableCampaignsTemplateId: templateId.id,
          selectedTemplate: { name: templateName, id: templateId.id },
        }
      })
      setRefreshTemplatesTrigger((prev) => prev + 1);
    } catch {
      setLoading(false);
      setError({ open: true, message: 'Error creating template' });
    }
  }

  return (
    <>
      <Input
        placeholder={'Template Name'}
        labelProps={{
          text: 'New Template',
        }}
        value={templateName}
        onChange={onTemplateNameChange}
        style={styles.input}
      />
      {
        loading ?
          <div css={styles.loader}>
            <Loader />
          </div>
          :
          <div css={styles.buttons}>
            <TextButton content={'Save As'}
              disabled={!templateName || !campaignsList}
              onClick={createTemplate}
            />
          </div>
      }
    </>
  )

}
