import { atom, selector } from 'recoil';
import { ConfigurationsServiceConfigsProvider } from '../../../../../services/configurations/ConfigurationsServiceConfigsProvider';


export const campaignsListState = atom({
  key: 'campaignsListState',
  default: [] as any[],
});

const campaignsDraftTriggeredInitialState = {
  triggered: false,
  loading: false,
  error: '',
};

export const campaignsDraftTriggeredState = atom({
  key: 'campaignsDraftTriggered',
  default: campaignsDraftTriggeredInitialState,
});

export const userConfiguration = selector({
  key: 'userConfigurationSelector',
  get: async () => {
    try {
      const response = await ConfigurationsServiceConfigsProvider.getConfigurations(true);
      return response.configs[0];
    } catch (error) {
      const errorMessage = 'Error fetching user configuration';
      throw new Error(errorMessage);
    }
  },
});

