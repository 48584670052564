import { selector, selectorFamily } from "recoil";
import { CampaignsServiceProvider } from "../../../../../../../../../../../services/campaigns/CampaignsServiceProvider";
import { templatesRefreshTrigger, templateState, templateUsersRefreshTrigger } from "./campaignsTemplate.atom";
import { UsersServiceConfigsProvider } from "../../../../../../../../../../../services/users/UsersServiceConfigsProvider";

export const getTemplates = selector({
  key: 'templatesSelector',
  get: async ({ get }) => {
    const refreshTrigger = get(templatesRefreshTrigger);
    try {
      const response = await CampaignsServiceProvider.getTemplates();
      return response;
    } catch (error) {
      const errorMessage = 'Error fetching templates:';
      throw new Error(errorMessage);
    }
  }
});


export const getUsers = selector({
  key: 'usersSelector',
  get: async () => {
    try {
      const response = await UsersServiceConfigsProvider.getUsersList();
      return response;
    } catch (error) {
      const errorMessage = 'Error fetching users:';
      throw new Error(errorMessage);
    }
  }
})

export const getTemplateUsers = selectorFamily({
  key: 'templateUsersSelector',
  get: (templateId) => async ({ get }) => {
    const refreshTrigger = get(templateUsersRefreshTrigger)
    const response = await CampaignsServiceProvider.getTemlateUsers(templateId as string)
    return response;
  },
});
