import { atom } from "recoil";

interface TemplateState {
  selectedTemplate: { id: string, name: string } | null;
  tableCampaignsTemplateId: string | null;
};

export const templateState = atom<TemplateState>({
  key: 'templateState',
  default: {} as TemplateState
});

export const templatesRefreshTrigger = atom({
  key: 'templatesRefreshTrigger',
  default: 0
});

export const templateUsersRefreshTrigger = atom({
  key: 'templateUsersRefreshTrigger',
  default: 0
});

