/** @jsxImportSource @emotion/react */
import { Button, CssTooltip, RegularTabs, TabData, Text, TOOLTIP_POSITIONS } from "@ftbpro/mm-admin-ui-components";
import { Settings } from "./components/Settings/Settings";
import { Target } from "./components/Target/Target";
import { css } from "@emotion/react";
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from 'recoil';
import { validateCampaign } from "./settingsAndTarget.validations";
import { settingsState } from "./components/Settings/settingsState/settings.atom";
import { targetState } from "./components/Target/targetState/target.atom";
import { campaignData } from "./components/Target/targetState/target.selector";
import { formatCampaignsDraftsForClient, formatCampaignToServer } from './settingsAndTarget.formatter';
import { campaignsDraftTriggeredState, campaignsListState } from '../../createCampaign.selector';
import { CampaignsServiceProvider } from '../../../../../../../services/campaigns/CampaignsServiceProvider';
import { templateState } from "./components/Target/components/CampaignsTemplate/campaignsTemplate.atom";

const styles = {
  container: css({
    backgroundColor: 'white',
    position: 'relative',
    overflowY: 'auto',
    zIndex: 2,
    width: '352px',
  }),
  content: css({
    overflowY: 'auto',
    height: 'calc(100vh - 180px)',
    position: 'relative',
  }),
  applyButton: css({
    position: 'fixed',
    bottom: '0',
    zIndex: 3,
    backgroundColor: 'white',
    padding: '16px 26px',
    display: 'flex',
    justifyContent: 'center',
  }),
  tooltip: {
    zIndex: 4,
  }
};

const TABS_VALUES = [
  {
    label: 'Settings',
    key: 'settings',
  },
  {
    label: 'Target',
    key: 'target',
  }
]

export const SettingsAndTarget = () => {
  const setTriggerState = useSetRecoilState(campaignsDraftTriggeredState);
  const [tab, setTab] = useState('settings');
  const campaignTarget = useRecoilValue(targetState);
  const campaignSettings = useRecoilValue(settingsState);
  const [campaignsList, setCampaignsList] = useRecoilState(campaignsListState);
  const data = useRecoilValueLoadable(campaignData);
  const [disabledApply, setDisabledApply] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const setTemplateState = useSetRecoilState(templateState);


  const onTabSelected = (tabData: TabData) => {
    setTab(tabData.key as string);
  }


  const onApplyDraft = async () => {
      try {
        setTriggerState({ triggered: true, loading: true, error: '' });
        const formattedDataToServer = formatCampaignToServer(campaignTarget, campaignSettings, data.contents?.article_id);
        const response = await CampaignsServiceProvider.generateCampaignsDraft(formattedDataToServer);
        const drafts = response.drafts;
        const formattedCampaignsDrafts = formatCampaignsDraftsForClient(drafts, campaignsList);
        setCampaignsList(formattedCampaignsDrafts);
        setTriggerState({ triggered: true, loading: false, error: '' });
        setTemplateState((prevTemplate) => {
          return {
            ...prevTemplate,
            draft: drafts
          }
        });
      } catch (error) {
        const errorMessage = 'Error generating campaigns drafts';
        setTriggerState({ triggered: true, loading: false, error: errorMessage });
      }
  }


  useEffect(() => {
    const errors = validateCampaign(campaignTarget, campaignSettings);
    if (errors.length > 0) {
      setErrors(errors);
      setDisabledApply(true);
    } else {
      setErrors([]);
      setDisabledApply(false);
    }
  }, [campaignTarget, campaignSettings])

  return (
    <div css={styles.container} >
      <RegularTabs
        tabs={TABS_VALUES}
        onTabSelected={onTabSelected}
      />
      <div css={styles.content}>
        {tab === 'settings' && <Settings />}
        {tab === 'target' && <Target />}
      </div>
      <div css={styles.applyButton}>
        <CssTooltip
          content={errors.length > 0 ? <TooltipContent errors={errors} /> : ''}
          style={styles.tooltip as any}
          position={TOOLTIP_POSITIONS.RIGHT}
          variables={{
            tooltipMultilineWidth: 'fit-content',
          }}
        >
          <Button
            content="Apply Draft"
            onClick={onApplyDraft}
            variables={{
              width: '300px',
              height: '32px',
            }}
            disabled={disabledApply}
          />
        </CssTooltip>
      </div>
    </div>
  );
}


const TooltipContent = ({ errors }: { errors: string[] }) => {
  return (
    <>
      {errors.map((error, index) => (
        <Text key={index} type={Text.TEXT_TYPES.PARAGRAPH_M}>{`- ${error}`}</Text>
      ))}
    </>
  );
};
