/** @jsxImportSource @emotion/react */
import React from "react";
import { Dropdown, DropdownListItemProps} from "@ftbpro/mm-admin-ui-components";
import { css } from "@emotion/react";
import { Card } from "../../../Card/Card";
import { getTemplates } from "./campaignsRemplate.selector";
import { useRecoilState, useRecoilValueLoadable } from "recoil";
import { templateState } from "./campaignsTemplate.atom";
import { CreateNewTemplate } from "./components/CreateNewTemplate/CreateNewTemplate";
import { SelectedTemplateActions } from "./components/CreateNewTemplate/SelectedTemplateActions/SelectedTemplateActions";
import { ClickEventType } from "@ftbpro/mm-admin-ui-components/src/types";


const styles = {
  card: {
    gap: 0,
  },
  buttons: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "right",
    gap: "24px",
  }),
  input: {
    marginTop: "24px"
  },
  snackBar: css({
    position: 'absolute',
    top: '-200px',
    right: '0px',
    width: '100%',
    height: '100%',
    zIndex: 1000,
  }),
}

export const CampaignsTemplate = () => {
  const templates = useRecoilValueLoadable(getTemplates);
  const [template, setTemplate] = useRecoilState(templateState);

  const getTemplateItems = () => {
    if (templates.state === 'hasValue' && Array.isArray(templates.contents?.templates)) {
      return templates.contents.templates.map((template: any) => ({ value: template.id, content: template.name }));
    }
    return [];
  }

  const onSeletedTemplateChange = (e: ClickEventType, selectedItem: DropdownListItemProps) => {
    setTemplate((prevTemplate) => {
      return {
        ...prevTemplate,
        selectedTemplate: { id: selectedItem.value as string, name: selectedItem.content as string },
      }
    });
  }
  return (
    <Card style={styles.card}>
      <Dropdown
        placeholder={'Select'}
        items={getTemplateItems()}
        labelProps={{
          text: 'Choose Template',
        }}
        search
        loading={templates.state === 'loading'}
        error={templates.state === 'hasError' ? 'Error fetching templates' : ''}
        selectedValue={template.selectedTemplate?.name || ''}
        onSelectedChange={onSeletedTemplateChange}
      />
      <SelectedTemplateActions />
      <CreateNewTemplate
      />
    </Card>
  );
}
