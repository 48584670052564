/** @jsxImportSource @emotion/react */
import React from "react";
import { Button, ButtonType, Text } from "@ftbpro/mm-admin-ui-components";
import { css } from "@emotion/react";
import { CampaignsListTable } from './components/CampaignsListTable/CampaignsListTable';
import { useSetRecoilState } from 'recoil';
import { campaignsDraftTriggeredState, campaignsListState } from '../../createCampaign.selector';

const styles = {
  container: {
    flexGrow: 3,
  },
  buttonContainer: css({
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#ffffff",
    alignItems: "center",
    justifyContent: "right",
    padding: "16px 20px",
    gap: '8px',
    height: '52px',
  }),
  total: {
    marginRight: "8px",
  }
}

const variables = {
  button: {
    width: "100px",
  }
}

export const CampaignsListContainer = () => {
  const setTriggerState = useSetRecoilState(campaignsDraftTriggeredState);
  const setCampaignsList = useSetRecoilState(campaignsListState);
  const onClearClick = () => {
    setTriggerState({ loading: false, triggered: false, error: '' });
    setCampaignsList([]);
  };

  return (
        <div css={styles.container}>
            <div css={styles.buttonContainer}>
                <Text type={Text.TEXT_TYPES.PARAGRAPH_M} color={"#7F7F7F"} style={styles.total}>Total Adsets:</Text>
                <Button type={ButtonType.Secondary} variables={variables.button} onClick={onClearClick}>Clear</Button>
                <Button type={ButtonType.Primary} variables={variables.button}>Submit</Button>
            </div>
            <CampaignsListTable/>
        </div>
    );
}
