/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { Loader, SNACK_BAR_STATUSES, TextButton } from "@ftbpro/mm-admin-ui-components"
import { useState } from "react";
import { getUserDetails } from "../../../../../../../../../../../../../../core/utils/user.utils";
import { useRecoilState, useRecoilValueLoadable, useSetRecoilState } from "recoil";
import { templatesRefreshTrigger, templateState } from "../../../campaignsTemplate.atom";
import { Template } from "../../../../../../../../../createCampign.typs";
import { getTemplates } from "../../../campaignsRemplate.selector";
import { CampaignsServiceProvider } from "../../../../../../../../../../../../../../services/campaigns/CampaignsServiceProvider";
import { campaignsDraftTriggeredState, campaignsListState } from "../../../../../../../../../createCampaign.selector";
import { formatCampaignsDraftsForClient } from "../../../../../../../settingsAndTarget.formatter";
import { ShareUsers } from "./components/ShareUsers/ShareUsers";
import { campaignMessageState } from "../../../../../../../../CreateCampaignMessage/createCampaignMessage.atom";

const styles = {
  buttons: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "right",
    gap: "24px",
  }),
  loader: css({
    display: "flex",
    justifyContent: "right",
    marginTop: "10px"
  }),
  snackBar: css({
    position: 'absolute',
    top: '-55px',
    right: '0px',
    width: '100%',
  }),
}

export const SelectedTemplateActions = () => {

  const [templateAtom, setTemplateAtom] = useRecoilState(templateState);
  const selectedTemplate = templateAtom.selectedTemplate;
  const campaignListTemplateId = templateAtom.tableCampaignsTemplateId;
  const templatesList = useRecoilValueLoadable(getTemplates)?.contents?.templates as Template[];
  const setTriggerState = useSetRecoilState(campaignsDraftTriggeredState);
  const [campaignsList, setCampaignsList] = useRecoilState(campaignsListState);
  const [shareUsers, setShareUsers] = useState(false);
  const [loading, setLoading] = useState(false);
  const setError = useSetRecoilState(campaignMessageState);
  const setRefreshTemplatesTrigger = useSetRecoilState(templatesRefreshTrigger);

  const isTemplateOwner = () => {

    if (!selectedTemplate || !templatesList) {
      return true;
    }
    const userId = getUserDetails().id;
    const owner = templatesList?.find((template) => template.id === selectedTemplate.id)?.ownerID;
    return owner === userId;
  }

  const onRunTemplate = async () => {
    try {
      setTriggerState({ triggered: true, loading: true, error: '' });
      const templateDraft = await CampaignsServiceProvider.getTemplateById(selectedTemplate?.id as string);
      const formattedTemplateDrafts = formatCampaignsDraftsForClient(templateDraft.template.template, campaignsList);
      setCampaignsList(formattedTemplateDrafts);
      setTriggerState({ triggered: true, loading: false, error: '' });
      setTemplateAtom((prevTemplate) => {
        return {
          ...prevTemplate,
          tableCampaignsTemplateId: selectedTemplate?.id as string,
        }
      });
    } catch {
      const errorMessage = 'Error generating template drafts';
      setTriggerState({ triggered: true, loading: false, error: errorMessage });
      setCampaignsList([]);
    }
  }

  const onEditTemplate = async () => {
    const updatedTemplate = {
      name: selectedTemplate?.name as string,
      platform: 'facebook',
      template: {
        template: campaignsList
      }
    }
    try {
      setLoading(true);
      await CampaignsServiceProvider.updateTemplate(selectedTemplate?.id as string, updatedTemplate)
      setLoading(false);
    } catch {
      setLoading(false);
      setError({ open: true, message: 'Failad to update template' });
    }
  }

  const onDeleteTemplate = async () => {
    try {
      setLoading(true);
      await CampaignsServiceProvider.deleteTemplate(selectedTemplate?.id as string);
      setLoading(false);
      setRefreshTemplatesTrigger((prev) => prev + 1);
      setTemplateAtom((prevTemplate) => {
        return {
          ...prevTemplate,
          selectedTemplate: null,
        }
      });
    } catch {
      setLoading(false);
      setError({ open: true, message: 'Failad to delete template', status: SNACK_BAR_STATUSES.ERROR });
    }
  }

  return (
    <>
      {
        loading ?
          <div css={styles.loader}>
            <Loader />
          </div>
          :
          <div css={styles.buttons}>
            {
              isTemplateOwner() &&
              <>
                <TextButton content={'Share'} disabled={!selectedTemplate} onClick={() => setShareUsers(true)} />
                <TextButton content={'Delete'} disabled={!selectedTemplate} onClick={onDeleteTemplate} />
                <TextButton content={'Save'} disabled={!selectedTemplate || (selectedTemplate.id !== campaignListTemplateId)} onClick={onEditTemplate} />
              </>
            }
            <TextButton content={'Run'} disabled={!selectedTemplate} onClick={onRunTemplate} />
          </div>
      }

      {
        shareUsers &&
        <ShareUsers onShareUser={() => setShareUsers(false)} />
      }
    </>
  )
}
